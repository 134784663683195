import React from 'react'
import '../components/styles/global.scss'
import Layout from '../components/common/Layout'
import { Link } from "gatsby"

export default function NotFound() {
  return (
    <Layout active=''
        title='PharmaEssentia Medical Affairs | Error'
        description='The page you selected does not exist.'
        socialTitle='PharmaEssentia Medical Affairs | Error'
        socialDescription='The page you selected does not exist.'
        pathname='/404'
        >
        <section id="error-404">
          <h1>Sorry</h1>
          <p>The page you selected does not exist.</p>
          <Link to='/'>GO TO HOME PAGE</Link>
        </section>
    </Layout>
  )
}
